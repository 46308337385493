.App {
    font-family: sans-serif;
    text-align: center;
  }
  h2 {
    font-size: 20px;
    padding: 10px;
    border-bottom: 1px solid grey;
  }
  h3 {
    font-size: 15px;
    padding: 10px;
  }
  .login,
.image {
  min-height: 100vh;
}



.button{

	background-color:#24DF53
}