
  .push-right {
    float: right;
    line-height: 50px;
  }
  .pagination {
    justify-content: left;
    border-radius: 5px;
    transition: background-color .3s;
  }
  
  